import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { backGroundImg, back, otpForgot, helpTitle } from "./assets";
import PhoneInput from "react-phone-number-input";
import OtpInput from 'react-otp-input';
import { I18n } from "../../../components/src/languageJson/i18n";

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});

const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  minHeight: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "80px", left: "25px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
});

const webStyle = {
  boxTitle: {
    display: "flex",
    justifyContent: "center", 
    height: "100%"
  },
  subBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "64px", 
    width: "100%", 
    margin: "auto",
    textAlign: "center" as "center"
  }, welcomeBox: {
    display: "flex", 
    alignItems: "center", 
    gap: "16px", 
    justifyContent: "center", 
    flexWrap: "wrap" as "wrap"
  },  helpTitle: {
    fontFamily: "Poppins", 
    fontSize: "58px", 
    lineHeight: "24px", 
    fontWeight: 600, 
    color: "#FFFFFF",
    whiteSpace: "nowrap"
  },
  helpSubTitle: {
    fontFamily: "Poppins",
    fontSize: "30px",
    lineHeight: "48px", fontWeight: 400, color: "#FFFFFF", whiteSpace: "nowrap"
  },
  imgTit: {
    height: "74px",
    width: "183px", textAlign: "center" as "center"
  },
  sign: {
    fontFamily: "Poppins",
    fontSize: "30px ",
    alignItems: "center",
    fontWeight: 700,
    justifyContent: "center",
    lineHeight: "40px",
    color: "#000000",
    marginTop: "24px",
    marginBottom: "24px",
  },
}

const BoxWidth = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
});

const BoxWidth100 = styled(Box)({
  width: "100%",
  "@media (min-width:410px) and (max-width:600px)": {
    width: "80%"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "75%"
  }
});


const GridContainer = styled(Grid)({
  width: "80vw",
  height: "100vh",
  '@media (max-width:1020px)': {
    justifyContent: "center",
  },

});


// Customizable Area End


import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";



export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGroundImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>
          <BackBox>
            <img style={{ height: "100", width: "100%" }} src={back} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoLogin} />
          </BackBox>

          <GridContainer container>
            <LeftGrid  item
              lg={6} md={4}
            >
              <Box style={webStyle.boxTitle}>
                <Box style={webStyle.subBox}>
                  <Box style={webStyle.welcomeBox}>
                    <Typography style={{...webStyle.helpTitle,
                       fontSize: 'clamp(3rem, 4vw, 4.5rem)',
                    }}>
                      {I18n.t("welcometo")}
                    </Typography>
                    <img src={helpTitle} style={webStyle.imgTit} />
                  </Box>
                  <Typography style={{  ...webStyle.helpSubTitle,
                     fontSize: 'clamp(1.5rem, 1.8vw, 2.2rem)',
                  }}>     {I18n.t("subTitle")}
                  </Typography>
                </Box>
              </Box>
            </LeftGrid>
            <Grid  item
              lg={6} md={8}
              sm={12}  xs={12}
              style={{
                display: "flex", 
                width: "100%",
                 height: "100%",
                 alignItems: "end",
                justifyContent: "center",
              }}
            >
              {!this.state.otpModal ? (
                <Box 
                  style={{ 
                    maxWidth: "100%", 
                    overflow: "hidden" ,
                    height: "85vh", 
                  }}>
                  <Box
                    style={{ height: "131px", backgroundColor: "#000000", borderTopLeftRadius: "45px", borderTopRightRadius: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box style={{ height: "74px", width: "183px" }}>
                      <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                    </Box>

                  </Box>
                  <BoxWidth647>
                    <Box
                      style={{
                        width: "535px",
                      }}
                    />
                    <BoxWidth>
                      <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px", marginTop: "24px" }}>
                        <Typography style={styles.heading}> {I18n.t("forgotPasswordTitle")}</Typography> 
                        <Typography style={styles.headingForgot}> {I18n.t("forgotSubtitle")}</Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <BoxWidth100>
                          {this.state.errorMessagePhone != "" && this.state.phoneNumber?.length > 0 && (
                            <ErrorMsg
                            >
                              <Typography data-test-id="error">
                                {this.state.errorMessagePhone}
                              </Typography>
                            </ErrorMsg>
                          )}
                          {this.state.apiResponseMessage && (
                            <ErrorMsg
                              style={this.state.forgotOTPvalue ? styles.apiForgotSuccess : styles.apiForgotFalied}
                            >
                              <Typography data-test-id="apiResponseMessage">
                                {this.state.apiResponseMessage}
                              </Typography>
                            </ErrorMsg>
                          )}
                          <PhoneStyle
                            placeholder={I18n.t("phoneNumber")}
                            data-test-id="phoneTestID"
                            value={this.state.phoneNumber}
                            onChange={(event: any) => {
                              this.handlePhoneNumber(event);
                            }}
                            phone
                            defaultCountry="AE"
                            countries={[]}
                            // international
                            countryCallingCodeEditable={false}
                            borderColor={this.state.borderColor}
                            onKeyPress={(event: React.KeyboardEvent) => {
                              if (event.key === 'Enter') {
                                this.handleNumberError();
                              }
                            }}
                            InputProps={{
                              style: {
                                textAlign: this.getValueBasedOnLanguage("right", "left"),
                                direction: this.getValueBasedOnLanguage("rtl", "ltr")
                              },
                            }}
                          />
                          <SubmitBtn
                            variant="contained"
                            // style={styles.buttonStyle}
                            data-test-id="nextBtnTestId"
                            onClick={this.handleNumberError}
                          >
                            {I18n.t("submit")}
                          </SubmitBtn>
                        </BoxWidth100>
                      </Box>
                    </BoxWidth>
                  </BoxWidth647>
                </Box>
              ) : (
                <div style={styles.root}>
                  <Paper elevation={3} style={styles.paper}>
                    <Box style={{ height: "122px", width: "160px" }}>
                      <img src={otpForgot} style={{ height: "100%", width: "100%" }} />
                    </Box>
                    <Box style={styles.otpBox}>
                      <Typography gutterBottom style={styles.headerOtp}>
                        Enter OTP Code
                      </Typography>
                      <OtpInput
                        value={this.state.forgotOTPvalue}
                        inputType="tel"
                        onChange={this.handleOtpChange}
                        containerStyle={{ outline: "none", gap: "24px" }}
                        data-test-id="otpinput"
                        numInputs={4}
                        renderInput={(props) => <input {...props} placeholder="-"
                          // style={{ ...props.style, borderColor: this.state.otpError ? 'red' : '#CBD5E1',}}
                          disabled />}
                        inputStyle={styles.otpInputt}
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        style={styles.otpButton}
                        onClick={this.handleVerifyOTP}
                        data-test-id="nextBtnTestIdotp"
                      // onClick={() => this.handleAllValidation()}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Box>
                      <Typography variant="body2" style={styles.resendLink}>
                        <span onClick={this.handleSendOTP} style={styles.resended}>Resend</span> OTP Code
                      </Typography>
                    </Box>
                  </Paper>
                </div>
              )}
            </Grid>
          </GridContainer>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const InputField = styled(TextField)({
  minWidth: "485px",
  marginBottom: "24px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});

const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },

  fontFamily: "Poppins",
  fontSize: "56px ",
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  fontWeight: 600,
  marginBottom: "9px ",
  color: "#0F172A",


});


const ErrorMsg = styled(Box)({
  borderLeft: "4px solid red",
  padding: "12px",
  backgroundColor: "#FEE2E2",
  marginBottom: "24px",
});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },

  display: "flex",
  justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ",
  fontSize: "22px ",
  fontWeight: 400,
  color: "#0F172A",
  marginBottom: "64px",


});


const SubmitBtn = styled(Button)({
  background: "#527FE2",
  height: "61px",
  padding: "16px",
  gap: "8px",
  borderRadius: "8px",
  color: "#FFF",
  marginTop: "48px",
  fontFamily: "Poppins",
  fontSize: "20px ",
  fontWeight: 600,
  lineHeight: "24px",
  cursor: "pointer",
  width: "100%",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
});

const styles: { [key: string]: React.CSSProperties } = {
  otpBox:{display:"flex",flexDirection:"column",gap:"20px"},
  heading: {
    fontFamily: "Poppins ",
    fontSize: "48px ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    lineHeight: "56px ",
    marginBottom: "24px ",
    marginTop: "24px",
    cursor: "pointer"
  },
  headingForgot: {
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 400,
    lineHeight: "28px ",
    color: "#000000"
  },
  backArroe: {
    maxWidth: "3.5rem", cursor: "pointer", marginLeft: "5rem", padding: "1rem"
  },
  apiForgotSuccess: {
    borderLeft: "4px solid #059669",
    padding: "12px",
    backgroundColor: "#D1FAE5",
    marginBottom: "24px",
  },
  apiForgotFalied: {
    borderLeft: "4px solid #DC2626",
    padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px",
  },
  buttonStyle: {
    background: "#527FE2",
    height: "64px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    // marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
  },
  accountStyleBold: {
    fontFamily: "Poppins ",
    fontSize: "16px ",
    cursor: "pointer",
    lineHeight: "24px",
    color: "#527FE2",
  },
  modal: {
    position:
      'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 350,
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: 24,

  },
  otpButton: {
    background:"#527FE2",
    width: "485px",
    height: "61px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontFamily: "Poppins ",
    fontSize: "16px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
  },
  resendText: {
    marginTop: '40px',
    fontFamily: "Poppins ",
    fontSize: "18px ",
    lineHeight: "26px",
  },
  ResendStyle: {
    fontWeight: 700,
    color: "#527FE2",
    cursor: 'pointer',
  },

  otpError: {
    fontWeight: '400',
    color: '#DC2626',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '600px',
    width: "600px",
    textAlign: "center",
    margin:"auto"
  },
  paper: {
    width: 488,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: "30px",
    padding: "30px"

  },
  headerOtp: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Poppins",
    color: "#0F172A",
    lineHeight: "40px"
  },
  inputBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: "24px"
  },
  otpInputt: {
    width: 73,
    height: 73,
    textAlign: 'center',
    borderRadius: "8px",
    border: "1px solid #CBD5E1",

  },
  submitButton: {
    width: '488px',
    backgroundColor: "#527FE2",
    textTransform: "capitalize",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "20px",
    fontFamily: "Poppins",
    lineHeight: "26px",
    fontWeight: 600

  },
  resendLink: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Poppins",
    color: "#0F172A",
    lineHeight: "26px"
  },
  resended: {
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    color: "#527FE2",
    lineHeight: "26px",
    textDecoration: "none",
  },

};

const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
  border: `1px solid ${borderColor || "#527FE2"}`,
  // width: "647px",
  borderRadius: 8,
  height: 59,
  marginBottom: "24px",
  zIndex: 1,
  position: "relative",
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  "& input": {
    border: "none",
  },
  "& input:focus": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputInput": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",
    lineHeight: "24px",
  },
  "& input:focus-visible": {
    border: "none",
    outline: "none",
  },
  "&:focus-within": {
    borderColor: "#6EE7B7",
  },
}));

// Customizable Area End
