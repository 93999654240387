import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from ".../../../packages/framework/src/Utilities";


// Customizable Area Start
import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  EngServiceName:string;
  ArabicServiceName:string;
  SerrvicePrice: string;
  PickupChecked:boolean;
  PickupFees:string;
  isDesablePickup:boolean;
  fieldErrors:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddServiceController extends BlockComponent<Props, S, SS> {
      // Customizable Area Start
 
  getProductApiCallId: any;
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

      this.state = {
          // Customizable Area Start

          arrayHolder: [],
          token: "",
          EngServiceName: "",
          ArabicServiceName: "",
          SerrvicePrice:"",
          PickupChecked: false,
          PickupFees: "",
          isDesablePickup: true,
          fieldErrors:{}
          // Customizable Area End
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  
  }



  AddServicePost = async(tokenvalue:string) => {
    const header = {
      token:tokenvalue,
    };
    const formData:any = new FormData();
    formData.append("name", this.state.EngServiceName);
    formData.append("arabic_name", this.state.ArabicServiceName);
    this.state.PickupChecked && formData.append("pickup_and_delivery_fees", this.state.PickupFees);
    formData.append("pickup_and_delivery", this.state.PickupChecked);
    formData.append("price", this.state.SerrvicePrice);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  };

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }


  AddServiceSchema = () => {
    const arabicRegex = /^[\u0600-\u06FF\s]+$/;
    return Yup.object().shape({
      EnglishName: Yup.string() .required("*Please enter a valid service name in english."),
      ArabicName: Yup.string().required("*Please enter a valid service name in ِarabic.").matches(arabicRegex,"*Please enter a valid service name in ِarabic."),
      ServicePrice:  Yup.string().required("Please enter a valid numeric price.")
      .test('is-number', 'Price must be a valid number', value => {
        const number = parseFloat(value);
        return !isNaN(number); 
      })
    });
  };

  validateAddService = async() => {
    const schema = this.AddServiceSchema();
    schema
      .validate({ EnglishName: this.state.EngServiceName ,
        ArabicName:this.state.ArabicServiceName,
        ServicePrice:parseInt(this.state.SerrvicePrice),
       
      }, { abortEarly: false })
      .then(async() => {
        let tokenvalue = await getStorageData('token')
       this.AddServicePost(tokenvalue)
       
      })
      .catch((validationError) => {
        const newErrors: Record<string, string> = {};
        validationError.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        this.setState({
            fieldErrors: newErrors,
        });
      });
  };


  handleCHeckPrice=()=>{
   this.setState({PickupChecked:!this.state.PickupChecked})
   
  }

  onValueChange = (name: string, value: string |null) => {
   
    this.setState({ ...this.state, [name]: value });
  };


  // Customizable Area End
}
